const enum Language {
  ES_ES = "es_es",
  EN_EN = "en_en",
}
const defaultLanguage: Language = Language.ES_ES;

export { defaultLanguage, Language };


export const Languages = [
  {title: 'Español', value: 'es_es'},
  {title: 'Inglés', value: 'en_en'},
]
